import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Spacer,
  Container,
  Row,
  Col,
  Bullet,
  Heading,
  Paragraph,
  Icon
} from '~/styles/components'

import { BigMomentButton } from './style'

import { useCraft } from './query'
import { mapCraft } from './mappings'

const BigMomentsBlock = ({ heading, subheading }) => {
  const craft = useCraft(mapCraft)
  const { items } = craft.bigMoments

  return (
    <Box>
      <Spacer size="md" />
      <Container>
        <Row>
          {heading &&
            <Col lg={6} marginTop={{ _: 0, lg: '-0.75rem' }}>
              <Heading level="2">
                {heading}
              </Heading>
              <Paragraph large>
                {subheading}
              </Paragraph>
              <Spacer size="sm" />
            </Col>
          }
          {!heading && <Col lg={6} />}
          <Col lg={6}>
            {items.map((item, i) =>
              <BigMomentButton key={i} to={`/${item.uri}`}>
                <Bullet
                  bg={['yellow', 'purple', 'green', 'orange', 'pink'][i % 5]}
                  color={['black', 'white', 'black', 'black', 'black'][i % 5]}
                  icon
                  large
                >
                  <Icon name={item.icon} />
                </Bullet>
                <Box as="span" marginTop="-.25rem">
                  {item.title}
                </Box>
              </BigMomentButton>
            )}
          </Col>
        </Row>
      </Container>
      <Spacer size="md" />
    </Box>
  )
}

BigMomentsBlock.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string
    })
  ),
}

export default BigMomentsBlock
import { only }  from '~/utils/mapping-filters'

const mapWhatsHealthy = (whatsHealthy) => {
  const whatsHealthyBlock = only(whatsHealthy.whatsHealthyBlock)
  return {
    heading: whatsHealthyBlock.heading,
    subheading: whatsHealthyBlock.subheading,
    body: whatsHealthyBlock.body,
    pageLink: only(whatsHealthyBlock.entry).uri || '',
    warningSigns: whatsHealthyBlock.warningSigns
  }
}

export const mapCraft = (craft) => {
  return {
    whatsHealthy: mapWhatsHealthy(craft.whatsHealthy)
  }
}
import styled from 'styled-components'
import { Button } from '~/styles/components'
import theme from '~/styles/theme'

export const BigMomentButton = styled(Button).attrs({
  large: true
})`
  margin-bottom: 1rem;
  padding: 2.5rem 1.5rem;
  border: 1px dashed rgba(0,0,0,0.5);
  border-radius: 2px;
  width: 100%;
  transition: background 150ms ease-in-out;
  justify-content: flex-start;
  line-height: 100%;

  &:hover {
    background: ${theme.colors.cream}80;
    border: 1px dashed rgba(0,0,0,0.5); /* Reset button style */
  }
`
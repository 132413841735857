import { only } from '~/utils/mapping-filters.js'

const mapEntry = (entry) => {
  const introBlock = only(entry.introBlock)
  const moneyInRelationshipsBlock = only(entry.moneyInRelationshipsBlock)
  const bigMomentsBlock = only(entry.bigMomentsBlock)
  return {
    metadata: only(entry.metadata),
    introBlock,
    moneyInRelationshipsBlock: {
      heading: moneyInRelationshipsBlock.heading,
      subheading: moneyInRelationshipsBlock.subheading,
      pageLink: only(moneyInRelationshipsBlock.entry).uri || ''
    },
    bigMomentsBlock: {
      heading: bigMomentsBlock.heading,
      subheading: bigMomentsBlock.subheading
    }
  }
}

export const mapCraft = (craft) => {
  return {
    entry: mapEntry(craft.entry)
  }
}
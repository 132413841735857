import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
{
  craft {
    bigMoments: globalSet(handle: "bigMoments") {
      ... on Craft_bigMoments_GlobalSet {
        items: bigMoments {
          ... on Craft_bigMoments_bigMoments_Entry {
            title
            uri
            icon: bigMomentIcon
          }
        }
      }
    }
  }
}
`

export const useCraft = (mapCraft = craft => craft) => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}
const mapBigMoments = (bigMoments) => {
  return {
    items: bigMoments.items
  }
}

export const mapCraft = (craft) => {
  return {
    bigMoments: mapBigMoments(craft.bigMoments)
  }
}
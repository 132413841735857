import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
{
  craft {
    whatsHealthy: globalSet(handle: "whatsHealthy") {
      ... on Craft_whatsHealthy_GlobalSet {
        whatsHealthyBlock {
          ... on Craft_whatsHealthyBlock_BlockType {
            heading
            subheading
            body
            entry {
              uri
            }
            warningSigns
          }
        }
      }
    }
  }
}
`

export const useCraft = (mapCraft = craft => craft) => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}
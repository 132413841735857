import styled from 'styled-components'
import theme from '~/styles/theme'

export const WarningSignsCard = styled.div`
  background: ${theme.colors.white};
  color: ${theme.colors.black};
  border-radius: 3rem;
  padding: 3rem;

  h4,
  h5,
  h6 {
    color: ${theme.colors.purple};
  }
`
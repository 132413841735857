import React from 'react'

import {
  Paragraph,
  Heading,
  Container,
  Box,
  Row,
  Col,
  Spacer,
  WavePattern,
  Button,
  Icon,
  Markup
} from '~/styles/components'
import { WarningSignsCard } from './style'

import { useCraft } from './query'
import { mapCraft } from './mappings'

const WhatsHealthyBlock = () => {
  const craft = useCraft(mapCraft)
  const {
    heading,
    subheading,
    body,
    pageLink,
    warningSigns
  } = craft.whatsHealthy

  return (
    <Box>
      <WavePattern
        color="purple"
        size="sm"
      />
      <Box
        bg="purple"
        color="white"
      >
        <Spacer size={{ _: 'md', lg: 'lg' }} />
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <Heading
                level="2"
                marginTop={{ _: '0', lg: '-.75rem' }}
              >
                {heading}
              </Heading>
              <Paragraph large>
                {subheading}
              </Paragraph>
              <Spacer size="0" />
            </Col>
            <Col xs={12} lg={6}>
              <Markup
                typography="paragraph"
                html={body}
              />
              <Spacer size="sm" />
              <Row>
                <Col xs={12} lg={10}>
                  <Button
                    outline
                    wide
                    color="white"
                    to={`/${pageLink}`}
                  >
                    Find out what&apos;s healthy
                    <Icon name="arrow" />
                  </Button>
                </Col>
              </Row>
              <Spacer size="md" />
              <WarningSignsCard>
                <Heading level="3">
                  Warning signs
                </Heading>
                <Markup
                  typography="paragraph"
                  html={warningSigns}
                />
              </WarningSignsCard>
            </Col>
          </Row>
        </Container>
        <Spacer size={{ _: 'md', lg: 'lg' }} />
        <Spacer size="xs" />
      </Box>
      <WavePattern
        color="purple"
        bottom
        size="sm"
      />
    </Box>
  )
}

export default WhatsHealthyBlock
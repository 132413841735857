import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Metadata from '~/components/Metadata'
import WhatsHealthyBlock from '~/components/WhatsHealthyBlock'
import BigMomentsBlock from '~/components/BigMomentsBlock'

import {
  Paragraph,
  Heading,
  Highlight,
  Bullet,
  Container,
  Box,
  Row,
  Col,
  Spacer,
  DripPattern,
  Button,
  Icon,
  Character
} from '~/styles/components'

import { mapCraft } from './mappings'

const HomeTemplate = ({ data }) => {
  const craft = mapCraft(data.craft)
  const {
    metadata,
    introBlock,
    moneyInRelationshipsBlock,
    bigMomentsBlock
  } = craft.entry

  return (
    <div>
      <Metadata
        {...metadata}
      />
      <Box>
        <DripPattern
          color="cream"
        />
        <Spacer size="md" />
        <Spacer size={{ _: 'xxs', lg: 'sm' }} />
        <Container>
          <Row>
            <Col xs={12} sm={10}>
              <Heading level="1">
                <i>Talk some cents<br/>into</i> <Highlight color="pink">your</Highlight> <Highlight color="orange">relationship.</Highlight>
              </Heading>
              <Spacer size={{ _: 'xxs', lg: 'sm' }} />
              <Button
                to="/quiz"
                shadow
                large
              >
                Take the quiz
                <Icon name="arrow" marginLeft="4rem" />
              </Button>
            </Col>
            <Col xs={12} sm={2}>
              <Box
                position="relative"
                width="100%"
                height="md"
              >
                <Character
                  name="freedom"
                  size={{ _: '300px', sm: '320px', md: '400px', lg: '480px' }}
                  top={{ _: '60px', sm: '300px', md: '240px', lg: '370px' }}
                  right={{ _: '110px', sm: '60px', md: '70px', lg: '90px' }}
                />
                <Character
                  name="power"
                  size={{ _: '300px', sm: '320px', md: '400px', lg: '480px' }}
                  top={{ _: '20px', sm: '120px', md: '40px', lg: '90px' }}
                  right={{ _: '-100px', sm: '-110px', md: '-130px', lg: '-150px' }}
                />
              </Box>
            </Col>
          </Row>
        </Container>
        <Spacer size="xl" />
        <Spacer size={{ _: 'xl', sm: 'lg' }} />
      </Box>
      <Box>
        <Spacer size="md" />
        <Container>
          <Row>
            <Col lg={6}>
              <Heading level="2">
                {introBlock.heading}
              </Heading>
              <Paragraph>
                {introBlock.subheading}
              </Paragraph>
              <Spacer size="xxs" />
            </Col>
            <Col lg={6}>
              <Spacer size={{ _: 'sm', lg: 'md' }} />
              <Spacer size={{ _: 0, lg: 'lg' }} />
              {introBlock.steps && introBlock.steps.map((step, i) =>
                <React.Fragment key={i}>
                  <Heading
                    level="4"
                    display="flex"
                    alignItems="baseline"
                  >
                    <Bullet
                      bg={['yellow', 'orange', 'green'][i % 3]}
                      color={['black', 'black', 'black'][i % 3]}
                      number={i + 1}
                    />
                    {step.heading}
                  </Heading>
                  <Paragraph>
                    {step.body}
                  </Paragraph>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Container>
      </Box>
      <Spacer size="lg" />
      <Spacer size={{ _: 0, lg: 'md' }} />
      <Box>
        <Container>
          <Row>
            <Col lg={6}>
              <Heading
                level="2"
                marginTop={{ _: '0', lg: '-.75rem' }}
              >
                {moneyInRelationshipsBlock.heading}
              </Heading>
              <Spacer size="0" />
            </Col>
            <Col lg={6}>
              <Paragraph large>
                {moneyInRelationshipsBlock.subheading}
              </Paragraph>
              <Spacer size="xs" />
              <Row>
                <Col xs={12} lg={10}>
                  <Button
                    outline
                    wide
                    color="purple"
                    to={`/${moneyInRelationshipsBlock.pageLink}`}
                  >
                    Talk to your partner
                    <Icon name="arrow" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Box>
      <Spacer size="lg" />
      <Box>
        <Container>
          <Box
            position="relative"
            width="100%"
            height={{ _: '260px', sm: '300px', md: '400px', lg: '540px' }}
          >
            <Character
              name="security"
              size={{ _: '260px', sm: '300px', md: '360px', lg: '420px' }}
              top={{ _: '40px', sm: '50px', md: '80px', lg: '-160px' }}
              left={{ _: '-40px', sm: '0', md: '0', lg: '60px' }}
              zIndex="2"
            />
            <Character
              name="love"
              size={{ _: '260px', sm: '300px', md: '360px', lg: '420px' }}
              top={{ _: '-60px', sm: '-60px', md: '-50px', lg: '100px' }}
              left={{ _: '120px', sm: '180px', md: '220px', lg: '320px' }}
            />
          </Box>
        </Container>
      </Box>
      <Spacer size="md" />
      <BigMomentsBlock
        {...bigMomentsBlock}
      />
      <Spacer size="md" />
      <Spacer size="sm" />
      <WhatsHealthyBlock />
    </div>
  )
}

HomeTemplate.propTypes = {
  data: PropTypes.object
}

export default HomeTemplate

export const query = graphql`
query HomeTemplateQuery($id: Craft_QueryArgument) {
  craft {
    entry(id: [$id]) {
      ... on Craft_home_home_Entry {
        metadata {
          ... on Craft_metadata_BlockType {
            title: metadataTitle
            description: metadataDescription
            keywords: metadataKeywords
            noFollow: metadataNoFollow
            noIndex: metadataNoIndex
          }
        }
        introBlock: homeIntroBlock {
          ... on Craft_homeIntroBlock_BlockType {
            heading
            subheading
            steps {
              heading
              body
            }
          }
        }
        moneyInRelationshipsBlock: homeMoneyInRelationshipsBlock {
          ... on Craft_homeMoneyInRelationshipsBlock_BlockType {
            heading
            subheading
            entry {
              uri
            }
          }
        }
        bigMomentsBlock: homeBigMomentsBlock {
          ... on Craft_homeBigMomentsBlock_BlockType {
            heading
            subheading
          }
        }
      }
    }
  }
}
`
